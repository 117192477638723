import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import About from './About';
import Services from './Services';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SEO from './components/SEO/SEO';

ReactDOM.render(
  <BrowserRouter>
   <SEO title={"Mako Mechanical | 24 Hour Mechanical Services | Vancouver, BC"}/>
  <Routes>
    <Route path='/' element={<App />} />
    <Route path='about' element={<About />} />
    <Route path='services' element={<Services />} />
    <Route path="*" element={
    <Navigate to="/" replace />} />
  </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();
