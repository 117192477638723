import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../App"
import SwiperCore, { Autoplay} from 'swiper'

SwiperCore.use([Autoplay])

export const Hero= () => {
  return (
    <>
      {/* <Swiper
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        navigation={false}
        modules={[Pagination, Zoom]}
        className="mySwiper"
        coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 0,
            modifier: 0,
            slideShadows: false,
          }}
          autoplay={{
            delay: 5000,
            }}
         loop={true}
      > */}
        {/* <SwiperSlide>  */}
          <div className="relative w-screen">
          <picture>
          <source type="image/webp" alt="heroimage" srcset="assets/images/img1.webp" className="opacity-60 saturate-150 swiper-slide object-cover backdrop-blur-sm object-center h-[75vh] min-h-[500px] max-h-[700px]"  />
          <img className="opacity-60 saturate-150 swiper-slide object-cover backdrop-blur-sm object-center h-[75vh] min-h-[500px] max-h-[700px]" 
              src="assets/images/img1.jpg" alt="heroimage" />
          </picture>
          <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2
           font-coffee-bd  text-6xl md:text-7xl lg:text-7xl xl:text-8xl 2xl:text-8xl tracking-widest whitespace-pre-wrap lg:whitespace-nowrap overflow-hidden">
            24 Hour<br/>
            Mechanical Services<br/>
            604.517.5792
            {/* <motion.h1
              initial={{scale: 0.5, y: -10}}
              animate={{ scale: 1, y: 0}}
              transition={{
                delay: .7,
              duration: .5
              }}
            >24 Hour</motion.h1>
            <motion.h1
             initial={{scale: 0.5, y: -10}}
             animate={{ scale: 1, y: 0}}
             transition={{
              delay: .7,
             duration: .5 }}
            >Mechanical Services</motion.h1>
            <motion.h1
             initial={{scale: 0.5, y: -10}}
             animate={{ scale: 1, y: 0}}
             transition={{
              delay: .7,
             duration: .5 }}
            >604.517.5792</motion.h1> */}
          </div>
          {/* <motion.div
            initial={{scale: 0.2, x: 500}}
            animate={{ scale: 1, x: 0}}
            transition={{
            delay: 1.2,
            duration: .8 }}
          className="flex justify-center items-center absolute top-1/2 right-0 bottom-4 lg:bottom-36 lg:right-20 xl:right-20 2xl:right-1/4">
          <img src="assets/images/truck.png" alt="makotruck" className="w-3/4 lg:w-11/12 object-cover" />
          </motion.div> */}
          </div>
        {/* </SwiperSlide> */}
        {/* <SwiperSlide>
        {({ isActive }) => (
        <div className="w-full overflow-hidden">
        <motion.img
          initial={{scale: 1.2}}
          animate={{scale: isActive ? 1 : 1.2}}
          transition={{
            duration: 1.2 }}
           className="swiper-slide object-cover" src="assets/images/img2.jpg" style={{maxHeight: '650px', height: "65vh"}} alt="slider-pic" />  
        </div>
        )}
        </SwiperSlide>
        <SwiperSlide> 
        {({ isActive }) => (
        <div className="w-full overflow-hidden">
        <motion.img
           initial={{scale: 1.2}}
           animate={{scale: isActive ? 1 : 1.2}}
           transition={{
             duration: 1.2 }}
           className="swiper-slide object-cover" src="assets/images/img3.jpg" style={{maxHeight: '650px', height: "65vh"}} alt="slider-pic" />  
        </div>
        )}
          </SwiperSlide>  */}
      {/* </Swiper> */}
    </>
  );
}
