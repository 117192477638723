import styled from 'styled-components'

export const AboutSectionBg = styled.div`
    background-image: url('assets/images/Shark.svg');
    background-color: '#ffff';
    background-repeat: no-repeat;
    background-size: 30%, cover;
    background-position: right 15%;
    width: 90%;
    margin: 0 auto;
    @media (max-width: 1024px){
      background: none;
    }
`