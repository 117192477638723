import styled from 'styled-components'

export const GradientCard = styled.a`
background: #ffff;
border-radius: 24px;
transition: all .3s;
&:hover{
    transform: translateY(-5px);
}
`
export const GradientCard2 = styled.div`
background: #ffff;
border-radius: 24px;
transition: all .3s;
&:hover{
    transform: translateY(-5px);
}
`
