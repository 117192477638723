import styled from 'styled-components'

export const AreaSectionImage = styled.div`
  background-image: url('assets/images/background.jpg');
    background-color: '#ffff';
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 30% 0 0 30%;
    position: relative;
    left: 5rem;
    @media (max-width: 640px){
      background-size: cover;
      border-radius: 50%;
    }
`