import React from 'react'
import HVAC from './ServiceBlock/HVAC'
import Maintenance from './ServiceBlock/Maintenance'
import Plumbing from './ServiceBlock/Plumbing'

export const ServicePageSection = () => {
  return (
    <div className='mt-8 md:mt-12'>
        <Plumbing />
        <HVAC />
        <Maintenance />
    </div>
  )
}
