import React from 'react'
import { HashLink } from 'react-router-hash-link';
import { GradientCard2 } from "./Card.styled";

export const LocationCard = () => {
  return (
    <HashLink to="/about#areas" className='md:w-fit w-full flex justify-center'>
    <GradientCard2 className="w-5/6 h-18 md:w-64 md:h-32 p-3 flex flex-col justify-center items-center gap-1 lg:gap-3 shadow-md">
      <div className="flex gap-2 items-center justify-center pr-3">
      <svg
            xmlns="http://www.w3.org/2000/svg"
            className="fill-blue w-6 h-6"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8l3-4z"
            />
            <path
              fillRule="evenodd"
              d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z"
            />
          </svg>
        <p className="tracking-wider font-coffee-rg text-2xl">AREAS OF SERVICE</p>
      </div>
      <p className="font-work font-semibold text-lg">Lower Mainland</p>
    </GradientCard2>
    </HashLink>
  )
}
