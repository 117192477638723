import React from "react";
import { Link } from "react-router-dom";
export const Footer = () => {
  return (
    <footer id="#contact" className="bg-dark-gray text-white font-work text-center text-sm lg:text-md pb-10 lg:pb-0 w-screen">
      <div className="container pt-8 mx-auto max-w-7xl">
        <div className="grid lg:grid-cols-4 md:grid-cols-2 items-start gap-5 lg:gap-0">
          <div className="mx-auto">
            <ul className="list-none flex flex-col items-center lg:items-start">
              <li className="mb-2">
                <img
                  src="assets/logos/logo-white.png"
                  alt="Mako Logo"
                  className="h-24 w-24 lg:h-28 lg:w-28 object-contain"
                />
              </li>
              <li className="mb-2 lg:text-left text-center">
                <a href="https://g.page/r/CQ3r8NXdgVYMEAE" target="_blank" rel="noreferrer">
                  460 Fraser View Place
                  <br />
                  Delta, British Columbia
                  <br />
                  Canada V3M 6H4
                </a>
              </li>
              <li>
                <a href="https://g.page/r/CQ3r8NXdgVYMEAE" target="_blank" rel="noreferrer" aria-label="Google">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className=" fill-aqua w-6 h-6"
                    viewBox="0 0 16 16"
                    alt="google_icon"
                  >
                    <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>

          <div className="flex flex-col justify-end">
            <h2 className=" font-bold text-md tracking-wider mb-4 text-lg">
              HOURS
            </h2>
            <ul className="list-none">
              <li className="flex justify-center mb-4">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.3512 13.0667C20.4385 13.1516 20.5078 13.2525 20.5551 13.3636C20.6023 13.4747 20.6266 13.5938 20.6266 13.714C20.6266 13.8343 20.6023 13.9534 20.5551 14.0644C20.5078 14.1755 20.4385 14.2764 20.3512 14.3613L14.7262 19.8471C14.6391 19.9323 14.5357 19.9998 14.4218 20.0459C14.3079 20.092 14.1858 20.1157 14.0625 20.1157C13.9392 20.1157 13.8171 20.092 13.7032 20.0459C13.5893 19.9998 13.4858 19.9323 13.3987 19.8471L10.5862 17.1042C10.499 17.0192 10.4299 16.9183 10.3827 16.8072C10.3356 16.6962 10.3113 16.5771 10.3113 16.4569C10.3113 16.3367 10.3356 16.2176 10.3827 16.1066C10.4299 15.9955 10.499 15.8946 10.5862 15.8096C10.7623 15.6379 11.001 15.5415 11.25 15.5415C11.3732 15.5415 11.4953 15.5651 11.6092 15.6111C11.7231 15.6571 11.8266 15.7246 11.9137 15.8096L14.0625 17.907L19.0237 13.0667C19.1108 12.9816 19.2143 12.914 19.3282 12.8679C19.4421 12.8218 19.5642 12.7981 19.6875 12.7981C19.8108 12.7981 19.9329 12.8218 20.0468 12.8679C20.1607 12.914 20.2641 12.9816 20.3512 13.0667Z"
                    fill="white"
                  />
                  <path
                    d="M6.5625 0C6.81114 0 7.0496 0.0963245 7.22541 0.267783C7.40123 0.439242 7.5 0.671789 7.5 0.914268V1.82854H22.5V0.914268C22.5 0.671789 22.5988 0.439242 22.7746 0.267783C22.9504 0.0963245 23.1889 0 23.4375 0C23.6861 0 23.9246 0.0963245 24.1004 0.267783C24.2762 0.439242 24.375 0.671789 24.375 0.914268V1.82854H26.25C27.2446 1.82854 28.1984 2.21383 28.9016 2.89967C29.6049 3.5855 30 4.51569 30 5.48561V25.5995C30 26.5694 29.6049 27.4996 28.9016 28.1855C28.1984 28.8713 27.2446 29.2566 26.25 29.2566H3.75C2.75544 29.2566 1.80161 28.8713 1.09835 28.1855C0.395088 27.4996 0 26.5694 0 25.5995V5.48561C0 4.51569 0.395088 3.5855 1.09835 2.89967C1.80161 2.21383 2.75544 1.82854 3.75 1.82854H5.625V0.914268C5.625 0.671789 5.72377 0.439242 5.89959 0.267783C6.0754 0.0963245 6.31386 0 6.5625 0V0ZM1.875 7.31415V25.5995C1.875 26.0845 2.07254 26.5496 2.42417 26.8925C2.77581 27.2354 3.25272 27.4281 3.75 27.4281H26.25C26.7473 27.4281 27.2242 27.2354 27.5758 26.8925C27.9275 26.5496 28.125 26.0845 28.125 25.5995V7.31415H1.875Z"
                    fill="#00A7B5"
                  />
                </svg>
              </li>
              <li>
                <h4 className="font-bold mb-2">Office Hours</h4>
              </li>
              <li>
                <p>9am to 5pm, Mon to Fri</p>
              </li>
              <li>
                <h4 className="font-bold my-2">Emergency Hours</h4>
              </li>
              <li>
                <p>Available 24-7</p>
              </li>
            </ul>
          </div>

          <div>
            <h2 className=" font-bold text-md tracking-widest mb-4 text-lg">SERVICES</h2>

            <ul className="list-none mb-0">
              <li className="flex justify-center mb-4">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_16_284)">
                    <path
                      d="M18.75 0.9375C18.75 0.68886 18.6512 0.450403 18.4754 0.274587C18.2996 0.0987721 18.0611 0 17.8125 0L12.1875 0C11.9389 0 11.7004 0.0987721 11.5246 0.274587C11.3488 0.450403 11.25 0.68886 11.25 0.9375C11.25 1.18614 11.1512 1.4246 10.9754 1.60041C10.7996 1.77623 10.5611 1.875 10.3125 1.875C10.0639 1.875 9.8254 1.97377 9.64959 2.14959C9.47377 2.3254 9.375 2.56386 9.375 2.8125V3.75C9.375 3.99864 9.47377 4.2371 9.64959 4.41291C9.8254 4.58873 10.0639 4.6875 10.3125 4.6875H19.6875C19.9361 4.6875 20.1746 4.58873 20.3504 4.41291C20.5262 4.2371 20.625 3.99864 20.625 3.75V2.8125C20.625 2.56386 20.5262 2.3254 20.3504 2.14959C20.1746 1.97377 19.9361 1.875 19.6875 1.875C19.4389 1.875 19.2004 1.77623 19.0246 1.60041C18.8488 1.4246 18.75 1.18614 18.75 0.9375Z"
                      fill="white"
                    />
                    <path
                      d="M7.65938 1.875H6.5625C5.81658 1.875 5.10121 2.17132 4.57376 2.69876C4.04632 3.22621 3.75 3.94158 3.75 4.6875V27.1875C3.75 27.9334 4.04632 28.6488 4.57376 29.1762C5.10121 29.7037 5.81658 30 6.5625 30H23.4375C24.1834 30 24.8988 29.7037 25.4262 29.1762C25.9537 28.6488 26.25 27.9334 26.25 27.1875V4.6875C26.25 3.94158 25.9537 3.22621 25.4262 2.69876C24.8988 2.17132 24.1834 1.875 23.4375 1.875H22.3406C22.4438 2.1675 22.5 2.48438 22.5 2.8125V3.75C22.5 4.49592 22.2037 5.21129 21.6762 5.73874C21.1488 6.26618 20.4334 6.5625 19.6875 6.5625H10.3125C9.56658 6.5625 8.85121 6.26618 8.32376 5.73874C7.79632 5.21129 7.5 4.49592 7.5 3.75V2.8125C7.5 2.48438 7.55625 2.1675 7.65938 1.875ZM20.3512 14.7262L14.7262 20.3512C14.6392 20.4386 14.5357 20.5078 14.4218 20.5551C14.3079 20.6023 14.1858 20.6267 14.0625 20.6267C13.9392 20.6267 13.8171 20.6023 13.7032 20.5551C13.5893 20.5078 13.4858 20.4386 13.3988 20.3512L10.5862 17.5387C10.4991 17.4516 10.4299 17.3481 10.3828 17.2342C10.3356 17.1203 10.3113 16.9983 10.3113 16.875C10.3113 16.7517 10.3356 16.6297 10.3828 16.5158C10.4299 16.4019 10.4991 16.2984 10.5862 16.2113C10.7623 16.0352 11.001 15.9363 11.25 15.9363C11.3733 15.9363 11.4953 15.9606 11.6092 16.0078C11.7231 16.0549 11.8266 16.1241 11.9137 16.2113L14.0625 18.3619L19.0238 13.3988C19.1998 13.2227 19.4385 13.1238 19.6875 13.1238C19.9365 13.1238 20.1752 13.2227 20.3512 13.3988C20.5273 13.5748 20.6262 13.8135 20.6262 14.0625C20.6262 14.3115 20.5273 14.5502 20.3512 14.7262Z"
                      fill="#00A7B5"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_16_284">
                      <rect width="30" height="30" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </li>

              <li>
              <Link to="/services"><h4 className="font-bold mb-2">Plumbing</h4></Link>
              <Link to="/services"> <h4 className="font-bold mb-2">HVAC</h4></Link>
              <Link to="/services"> <h4 className="font-bold mb-2">Maintenance</h4></Link>
              </li>
            </ul>
          </div>

          <div className="">
            <h2 className=" font-bold text-md tracking-widest mb-4 text-lg">CONTACT</h2>
            <ul className="list-none">
              <li className="pb-5">
                <a href="tel:604-517-5792" className="text-white">
                  <div className="flex gap-2 justify-center items-center mb-2 font-bold">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className=" fill-aqua w-5 h-5"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                      />
                    </svg>
                    PHONE
                  </div>
                  <p>604-517-5792</p>
                </a>
              </li>
              <li>
                <a href="mailto:service@makomechanical.ca" className="text-white">
                  <div className="flex justify-center items-center gap-2 mb-2 font-bold">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className=" fill-aqua w-5 h-5"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                    </svg>
                    EMAIL
                  </div>
                    <p>service@makomechanical.ca</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="text-gray-700 text-center text-xs pt-8 pb-4">
        ©2022 Mako Mechanical Ltd. All rights reserved
      </div>
    </footer>
  );
};
