import React from 'react'

const HVAC = () => {
  return (
    <div className='w-full md:w-11/12 2xl:w-5/6 mx-auto py-10 flex justify-around items-center flex-col-reverse gap-10 lg:flex-row text-black font-work'>
    <picture  className='w-full lg:w-1/2 mx-auto'>
     <source type="image/webp" srcset="assets/images/service2.webp" alt="service-img" className='w-full lg:w-5/6 mx-auto object-cover h-[180px] md:h-[330px]' />
    <img src="assets/images/service2.jpg" alt="service-img" className='w-full lg:w-5/6 mx-auto object-cover h-[180px] md:h-[330px]' />
    </picture>
    <div className='w-11/12 md:w-1/2 md:mt-0'>
        <h1 className='font-pluto uppercase tracking-widest my-2 text-lg lg:text-2xl'>HVAC</h1>
        <p className='my-5'>If you or your business requires heating or cooling services, we are here for you. We provide commercial, residential and industrial HVAC services, from variable refrigerant flow systems to heat pumps and everything in between, including repairs</p>
        <ul className='list-disc list-inside'>
       <p className='pb-1'>
            We Install and Service:
           </p>
        <li>Ventilation systems</li>
        <li>Cooling towers</li>
        <li>Chillers & condensers</li>
        <li>Hot water & heating boilers</li>
        <li>Heat pumps</li>
        <li>Air conditioning units</li>
        <li>Air handling units</li>
        <li>Heating and cooling rooftop units (RTUs)</li>
        <li>Tube heaters</li>
        <li>Commercial garage heaters</li>
        <li>Humidification/dehumidification systems</li>
        <li>Exhaust fans</li>
        <li>Pumps</li>
        <li>Heat/energy recovery systems</li>
        <p className='pl-5'>And more…</p>
        </ul>
    </div>
    </div>
  )
}

export default HVAC