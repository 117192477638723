import React from 'react'
import {Helmet} from "react-helmet";

const SEO = ({title}) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="icon" href="favicon.ico" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <link rel="apple-touch-icon" href="logo192.png" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Libre+Bodoni:wght@400;500;600;700&family=Roboto:wght@100;300;400;500;700;900&display=swap" rel="stylesheet"></link>
      <link rel="manifest" href="manifest.json" />
      <title>{title}</title>
      <meta
        name="description"
        content="Mako Mechanical is a full-service, one-stop company for all your plumbing, heating and cooling repair, replacement, and installations. Our technicians are licensed and professionally trained in installations, repairs, replacement, and maintenance of all makes and models of furnaces, heat pumps, boilers, air conditioners and more."
      />
    </Helmet>
  )
}

export default SEO