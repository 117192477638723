import React from 'react'
import { AboutSectionBg } from './AboutSectionBg'
import { Link } from 'react-router-dom'
import { SectionTitle } from './SectionTitle'

export const AboutSection = () => {
  return (
    <div className='relative mb-4 lg:mb-0'>
    <AboutSectionBg>
    <div className='w-full py-10 md:w-10/12 mx-auto lg:py-20'>
        <SectionTitle title={'ABOUT US'} />
        <div className='flex mx-auto mt-3 p-3 md:mt-10 lg:p-12 flex-col lg:flex-row justify-center items-center gap-5 lg:gap-0 lg:max-w-7xl'>
            <div className='w-11/12 lg:w-1/2 mx-auto'>
            <picture>
            <source type="image/webp" srcset="assets/images/top-about.webp" alt="aboutimg" className='object-cover w-full lg:w-10/12 mx-auto' />
            <img src="assets/images/top-about.jpg" alt="aboutimg" className='object-cover w-full lg:w-10/12 mx-auto'/>
          </picture>
            </div>
            <div className='w-full lg:w-1/3 mx-auto flex items-center flex-col gap-9'>
                <p className='bg-white/70 backdrop-blur-sm rounded-md'>Mako Mechanical is a full-service, one-stop company for all your plumbing, heating and cooling repair, replacement, and installations. Our technicians are licensed and professionally trained in installations, repairs, replacement, and maintenance of all makes and models of furnaces, heat pumps, boilers, air conditioners and more.
                <br/>
                We offer services including installations, repairs, maintenance, and 24/7 Emergency call-outs across Lower Mainland.
                </p>
                <Link to="/about" className='text-white bg-blue font-work font-semibold py-2.5 px-7 text-md rounded-sm tracking-widest shadow-md hover:-translate-y-1 hover:bg-aqua transition-all'>READ MORE</Link>
            </div>
        </div>
    </div>
    </AboutSectionBg>
    </div>
  )
}
