import React from "react";
import { GradientCard } from "./Card.styled";

const PhoneCard = () => {
  return (
    <GradientCard href='tel:604-517-5792' className="w-5/6 h-18 md:w-64 md:h-32 p-3 flex flex-col justify-center items-center gap-1 lg:gap-3 shadow-md">
      <div className="flex gap-2 items-center justify-center pr-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="fill-blue w-6 h-6"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
        />
      </svg>
      <p className="tracking-widest font-coffee-rg text-3xl">PHONE</p>
      </div>
      <p className="font-work font-semibold text-lg">604-517-5792</p>
    </GradientCard>
  );
};
export default PhoneCard;
