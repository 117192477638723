import styled from 'styled-components'

export const ServiceCardBg = styled.div`
background: linear-gradient(185.41deg, #FFFFFF 89.1%, #C4C4C4 151.09%);
border: 0.1px solid rgba(0, 0, 0, 0.25);
box-sizing: border-box;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 24px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
padding: 20px 10px;
min-height: 400px;
@media (max-width: 1000px){
    min-height: 250px;
}

`