import { BottomNav } from "./components/BottomNav";
import { Footer } from "./components/Footer";
import { SectionTitle } from "./components/SectionTitle";
import CardContainer from "./components/CardContainer.jsx";
import Form from "./components/Form";
import { Hero } from "./components/Hero";
import { ServiceCardSection } from "./components/ServiceCardSection";
import { AboutSection } from "./components/AboutSection";
import SEO from "./components/SEO/SEO";
import { NavBarHome } from "./components/NavBarHome";

function App() {
  return (
    <>
    <SEO title={"Mako Mechanical | 24 Hour Mechanical Services | Vancouver, BC"}/>
    <div className="w-screen overflow-x-hidden">
      <NavBarHome />
      <div className="mt-16 lg:mt-0">
        <Hero />
        <CardContainer />
        <div className="my-1">
        <AboutSection />
        </div>
        <ServiceCardSection />
        <div className="mt-14 lg:mt-36" id="contact">
        <SectionTitle title={'CONTACT'} />
        <Form />
        </div>
      </div>
      <Footer />
      <BottomNav />
    </div>
    </>
  );
}

export default App;
