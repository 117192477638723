import { useState, useEffect } from 'react'
import { HashLink } from 'react-router-hash-link';
import { GradientButton } from './Button.styled'

export const NavBarHome = () => {
  const [open, setOpen] = useState(false)
  const [scroll, setScroll] = useState(false)

  const handleScroll = () => {
    window.scrollY > 100 ? setScroll(true) : setScroll(false)
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll); 
    return () => window.removeEventListener("scroll", handleScroll);
  })
return (
<nav className={`fixed transition-all duration-200 ${scroll ? 'bg-white/50 backdrop-blur-sm ' : 'bg-transparent backdrop-blur-none'} px-1 py-1 md:px-2 md:py-2.5 font-pluto h-16 lg:h-24 w-full top-0 left-0 z-30`}>
<div className="container flex flex-wrap justify-between items-center mx-auto lg:static fixed top-0 left-0">
  {/* mobile-logo with animation */}
  <div className='block lg:hidden'>
  {/* <LogoAnimation> */}
  <HashLink to="/#top">
  <img src="assets/logos/logo-nav.svg" className="h-12 w-12 object-cover mx-2 mt-2 fixed z-50" alt="Mako Logo"/>
  </HashLink>
  {/* </LogoAnimation> */}
  </div>

<button onClick={() => setOpen((prev) => !prev)} aria-label="mobile_menu" className="fixed top-2.5 right-0 z-30 inline-flex items-center p-2 ml-2 text-sm text-black rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none">
{open ? 
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16" alt="menu_icon">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>
:
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16"  alt="menu_icon">
  <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
</svg>
}
</button>
<div className={`${open ? 'block' : 'hidden'} w-full lg:block lg:w-1/3 fixed lg:static top-0 left-0 pt-20 pb-10 lg:pt-0 lg:pb-0 bg-white backdrop-blur-md lg:backdrop-blur-none lg:bg-transparent`} id="mobile-menu">
<ul className="flex flex-col gap-1 mt-4 lg:flex-row lg:space-x-2 lg:mt-0 lg:text-sm lg:font-medium text-center flex-wrap items-center">
<li>
<HashLink to="/#top" className="block py-3 lg:py-2 lg:pl-3 text-black lg:border-0 lg:hover:text-blue lg:p-0 tracking-wider">HOME</HashLink>
</li>
<li>
<HashLink to="/about"
 scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'top' })}
className="block py-3 lg:py-2 lg:pl-3 text-black lg:border-0 lg:hover:text-blue lg:p-0 tracking-wider">ABOUT US</HashLink>
</li>
<li>
<HashLink to="/services" className="block py-3 lg:py-2 lg:pl-3 text-black lg:border-0 lg:hover:text-blue lg:p-0 tracking-wider">SERVICES</HashLink>
</li>
<li>
<HashLink to="/#contact" 
className="block py-3 lg:py-2 lg:pl-3 text-black lg:border-0 lg:hover:text-blue lg:p-0 tracking-wider">CONTACT</HashLink>

</li>
<li className='block lg:hidden'>
<a href="https://g.page/r/CQ3r8NXdgVYMEAE" target="_blank" rel="noreferrer" >
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="fill-blue" viewBox="0 0 16 16" alt="menu_icon">
  <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
</svg>
  </a>
</li>
<li className='visible lg:hidden mt-5'>
<GradientButton href='tel:604-517-5792 ' className='text-white font-work py-2 px-8 text-sm rounded-md font-semibold tracking-widest'>CALL NOW</GradientButton></li>
</ul>
</div>
{/* desktop-logo */}
<div className='hidden lg:block w-1/3'>
<HashLink to="/#top" className="flex justify-center">
<img src="assets/logos/logo-nav.svg" className={`transition-all duration-200 ${scroll ? 'h-20 w-20' : 'h-[120px] w-[120px]' }object-cover`} alt="Mako Logo"/>
</HashLink>
</div>
<div className='hidden lg:flex w-1/3 justify-end gap-7 items-center'>
<a href="https://g.page/r/CQ3r8NXdgVYMEAE" target="_blank" rel="noreferrer" aria-label="Google">
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="fill-blue" viewBox="0 0 16 16" alt="google_icon">
  <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
</svg>
  </a>
<a href='tel:604-517-5792 '>
<GradientButton href='tel:604-517-5792 ' className='text-white font-work py-2 px-8 text-md font-semibold rounded-md tracking-widest'>CALL NOW</GradientButton></a>
</div>
</div>
</nav>
  )
}
