import React from 'react'
import { CardFeaturedBg } from '../CardFeaturedBg'
import { CallButton } from '../Button'

const Plumbing = () => {
  return (
    <CardFeaturedBg>
    <div className='w-full py-10 md:w-11/12 2xl:w-5/6 mx-auto flex justify-center items-center flex-col gap-3 lg:flex-row text-white font-work'>
    <div className='w-11/12 md:w-1/2 md:mt-0'>
        <h1 className='font-pluto uppercase tracking-widest my-2 text-lg md:text-2xl'>Plumbing</h1>
        <p className='my-5'>A plumbing problem is the last thing anyone wants to deal with. Our experienced plumbers are patient and always prepared when it comes to the needs of our customers. We offer fast and efficient services and we do this without ever compromising our high standard of quality. Additionally, if you’re experiencing a plumbing emergency, don’t worry. We are available 24/7/365 for your convenience. </p>
        <ul className='list-disc list-inside'>
            <p class="pb-1">Our plumbing services include:</p>
            <li>Pipe repairs and installation</li>
            <li>Toilet repairs and installations</li>
            <li>Sink repair and installations</li>
            <li>Appliance installation</li>
            <li>Leak detection and prevention</li>
            <li>Sewer inspection</li>
            <li>Waterline repairs and replacement</li>
            <li>Clogged drains</li>
            <li>Plumbing video camera inspection</li>
            <li>Water heater repair and replacement</li>
            <li>Hydro-jetting</li>
            <li>Thermal imagery</li>
            <p className='pl-5'>And more…</p>
        </ul>
        {/* <div className='hidden md:block'>
        <CallButton />
        </div> */}
    </div>
    <picture  className='mt-5 lg:mt-0 w-full lg:w-1/2 mx-auto'>
     <source type="image/webp" srcset="assets/images/service1.webp" alt="serviceimg" className='w-full lg:w-5/6 mx-auto object-cover h-[180px] md:h-[330px]'  />
    <img src="assets/images/service1.jpg" alt="serviceimg" className='w-full lg:w-5/6 mx-auto object-cover h-[180px] md:h-[330px]' />
    </picture>
    </div>
</CardFeaturedBg>
  )
}

export default Plumbing