import React from 'react'

export const SectionTitle = ({title}) => {
  return (
    <div className='flex justify-center items-center flex-col gap-3 lg:gap-4'>
        <h1 className='font-pluto color-black font-bold tracking-widest text-2xl lg:text-4xl'>{title}</h1>
        <span className='bg-blue w-14 lg:w-20 h-1'></span>
    </div>
  )
}
