import React from 'react'
import { CardFeaturedBg } from '../CardFeaturedBg'

const Maintenance = () => {
  return (
    <CardFeaturedBg>
    <div className='w-full md:w-11/12 2xl:w-5/6 mx-auto pt-10 pb-10 flex justify-center items-center flex-col gap-10 lg:flex-row text-white font-work'>
    <div className='w-11/12 lg:w-1/2'>
        <h1 className='font-pluto uppercase tracking-widest my-2 text-lg lg:text-2xl'>Maintenance</h1>
        <p className='my-5'>It's easier to anticipate problems when mechanical equipment is properly and regularly maintained. To avoid costly repairs and overhauls, and prevent expensive downtime we offer the Scheduled Maintenance Program. Our professional technicians will check, calibrate, and correct commercial HVAC issues before they become a problem by opting into our Scheduled Maintenance Program.</p>
        <p className='my-5'>Our services cover ongoing maintenance, repairs, and modifications to all types of HVAC, Hydronic, and Plumbing systems. Those services vary greatly depending on the type of equipment and building requiring preventative maintenance.</p>
        <ul className='list-disc list-inside'>
            <p className='pb-1'>The most common types of buildings under Mako Mechanical's care are:</p>
            <li>Strata Residential Condo Towers</li>
            <li>Owner managed Commercial and Mixed-use Properties</li>
            <li>Institutional and Educational Industry Properties</li>
            <li>Industrial buildings</li>
            <li>Commercial buildings</li>
            <li>Custom HVAC and mechanical systems for large, detached, residential estates</li>
        </ul>
    </div>
    <div className='mt-5 lg:mt-0 w-full lg:w-1/2 mx-auto'>
    <picture>
     <source type="image/webp" srcset="assets/images/service3.webp" alt="service_img" className='w-full lg:w-5/6 mx-auto object-cover h-[180px] md:h-[330px]' />
    <img src="assets/images/service3.jpg" alt="service_img" className='w-full lg:w-5/6 mx-auto object-cover h-[180px] md:h-[330px]' />
    </picture>
    </div>
    </div>
   
</CardFeaturedBg>
  )
}

export default Maintenance