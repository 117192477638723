import { BottomNav } from "./components/BottomNav";
import { Footer } from "./components/Footer";
import { Navbar } from "./components/Navbar";
import { SectionTitle } from "./components/SectionTitle";
import { ServicePageSection } from "./components/ServicePageSection";
import { SubTitle } from "./components/SubTitle";
import SEO from "./components/SEO/SEO";
import ScrollToTop from "./ScrollToTop";

const Services = () => {
  return (
    <>
    <SEO title={"Mako Mechanical | Services | Commercial and Residential | Vancouver, BC"} />
    <ScrollToTop />
    <div className="w-screen overflow-x-hidden">
    <Navbar />
    <div className="mt-12 lg:mt-32">
    <div className="pt-10 pb-0">
    <SectionTitle title={'SERVICES'} />
    <SubTitle title={'COMMERCIAL & RESIDENTIAL'} />
    <div>
      </div>
      <ServicePageSection />
    </div>
    </div> 
    <Footer />
    <BottomNav />
  </div>
  </>
  );
};

export default Services;
