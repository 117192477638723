import React from "react";
import { ServiceCard } from "./ServiceCard";
import { CardFeaturedBg } from "./CardFeaturedBg";
import { SectionTitle } from "./SectionTitle";
import { Link } from "react-router-dom";
export const ServiceCardSection = () => {
  return (
    <>
      <SectionTitle title={"OUR SERVICES"} />
      <div className="mt-8 lg:mt-40">
        <CardFeaturedBg>
          <div className="w-full flex gap-3 2xl:gap-10 py-5 lg:py-0 justify-center align-center static lg:relative lg:-top-20 flex-col lg:flex-row max-w-7xl mx-auto lg:px-5">
            <ServiceCard
              title={"Plumbing"}
              detail={
               "When plumbing goes wrong, whether it is a simple clog or something more severe, we have the expertise to address your problem. "
              }
              detail2={
                "We provide a wide range of plumbing services, including pipe installation, drainage, toilet repairs, leak detection, faucet, and fixture installation or repairs, and more."
              }
            />
            <ServiceCard
              title={"HVAC"}
              detail={
              "Having properly working HVAC systems is essential to maintaining a comfortable temperature."
              }
              detail2={
                "Our skilled technicians will help make sure your entire system is functioning the way it should be, and if it’s not, they can offer the best solution to get it back in optimum condition."
              }
            />
            <ServiceCard
              title={"Maintenance"}
              detail={
                "Years of experience in the business have taught us that in this industry being proactive pays off tenfold in the long run."
              }
              detail2={
                "Preventing breakdowns, minimizing equipment downtime, increasing its lifespan, and avoiding unnecessary and costly repairs to save money and resources are something we take pride in."
              }
            />
          </div>
        <div className="flex justify-center pt-5 md:pt-0 pb-10 lg:pb-0 lg:relative lg:bottom-8">
          <Link
            to="/services"
            className="tracking-widest hover:bg-white px-12 lg:px-14 py-2 text-md lg:text-lg font-work font-semibold hover:text-blue border shadow-lg transition-all hover:-translate-y-1 bg-aqua text-white rounded-sm"
          >
            {/* <div className="flex justify-center items-center">      */}
            LEARN MORE
            {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" />
            </svg> */}
            {/* </div> */}
          </Link>
        </div>
        </CardFeaturedBg>
      </div>
    </>
  );
};
