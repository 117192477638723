import styled from 'styled-components'

export const FeaturedBg = styled.div`
    background-image: url('assets/images/background.jpg');
    background-color: '#ffff';
    background-repeat: no-repeat;
    background-size: cover;
`
export const FeaturedBgCard = styled.div`
    background-image: url('assets/images/background.jpg');
    background-color: '#ffff';
    background-repeat: no-repeat;
    background-size: cover;
`