import React from "react";
import PhoneCard from "./PhoneCard";
import EmailCard from "./EmailCard";
import { LocationCard } from "./LocationCard";
import { FeaturedBgCard } from "./FeaturedBg";

const CardContainer = () => {
  return (
    <FeaturedBgCard>
    <div className="w-full relative py-3 md:py-0 top-0 md:-top-5 z-10">
    <div className="flex gap-2 lg:gap-16 justify-center items-center flex-col md:flex-row">
      <PhoneCard />
      <EmailCard />
      <LocationCard />
    </div>
    </div>
      </FeaturedBgCard>
  );
};
export default CardContainer