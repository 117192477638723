import { AreaSection } from "./components/AreaSection";
import { BottomNav } from "./components/BottomNav";
import { Footer } from "./components/Footer";
import { Navbar } from "./components/Navbar";
import { SectionTitle } from "./components/SectionTitle";
import { SubTitle } from "./components/SubTitle";
import { Link } from "react-router-dom";
import SEO from "./components/SEO/SEO";
import ScrollToTop from "./ScrollToTop";


const About = () => {
  return (
    <>
    <ScrollToTop />
    <SEO title={"Mako Mechanical | About Us | Plumbing, Heating and Cooling | Vancouver, BC"} />
    <div className="w-screen overflow-x-hidden">
      <Navbar />
      <div className="mt-12 lg:mt-32">
        <div className="pt-10 mb-3 md:py-10">
      <SectionTitle title={'ABOUT US'} />
      <SubTitle title={'MAKO MECHANICAL'} />
        </div>
        <div className="w-11/12 mx-auto my-5">
        <p>Mako Mechanical is a full-service, one-stop company for all your plumbing, heating and cooling repair, replacement, and installations. Our technicians are licensed and professionally trained in installations, repairs, replacement, and maintenance of all makes and models of furnaces, heat pumps, boilers, air conditioners and more.</p>
        </div>
        <AreaSection />
        <div className="w-10/12 mx-auto mt-16 flex flex-col justify-center items-center">
        {/* <AboutPageSubTitle title={'TITLE'} />
        <p className="mt-8">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores magnam quo ipsa suscipit, non voluptates iusto? Maiores dignissimos amet, nemo quod eos odit necessitatibus quam. Placeat a pariatur eius provident?Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores magnam quo ipsa suscipit, non voluptates iusto? Maiores dignissimos amet, nemo quod eos odit necessitatibus quam. Placeat a pariatur eius provident?Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores magnam quo ipsa suscipit, non voluptates iusto? Maiores dignissimos amet, nemo quod eos odit necessitatibus quam. Placeat a pariatur eius provident?</p> */}
        <Link to="/services" className='text-white bg-blue font-work font-semibold py-2 px-8 shadow-md hover:-translate-y-1 hover:bg-aqua transition-all text-md rounded-sm tracking-widest my-9'>OUR SERVICES</Link>
        </div>
      </div> 
      <Footer />
      <BottomNav />
    </div>
    </>
  );
};
export default About;
