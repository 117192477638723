import React from "react";
import { GradientCard } from "./Card.styled";

const EmailCard = () => {
  return (
    <GradientCard href="mailto:service@makomechanical.ca" className="w-5/6 h-18 md:w-64 md:h-32 p-3 flex flex-col justify-center items-center gap-1 lg:gap-3 shadow-md">
      <div className="flex gap-2 items-center justify-center pr-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="fill-blue w-6 h-6"
          viewBox="0 0 16 16"
        >
          <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
        </svg>
        <p className="tracking-widest font-coffee-rg text-3xl">EMAIL</p>
      </div>
      <p className="font-work font-semibold text-lg">service@makomechanical.ca</p>
    </GradientCard>
  );
};
export default EmailCard;
