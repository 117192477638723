import React from 'react'
import { AboutPageSubTitle } from './AboutPageSubTitle'
import { AreaSectionImage } from './AreaSection.styled'

export const AreaSection = () => {
  return (
    <div id="areas" className='mt-8 mb-3 md:my-5'>
        <AboutPageSubTitle title={'AREA'} />
        <div className='flex flex-col md:flex-row justify-center items-center gap-5 md:gap-5 my-5 md:my-10'>
            <div className='w-full md:w-1/2 flex justify-center pl-0 lg:pl-12 2xl:pl-[71px]'>
                <p className='font-work w-11/12 md:w-4/5'>Mako Mechanical services the entire Lower Mainland. 
                  From Vancouver, North Vancouver, West Vancouver as well as the Greater Vancouver areas we will complete all plumbing, drainage and HVAC needs.
                  <br/>We are happy to help with any properties in the Valley or any building that's right in the downtown core. 
    <ul className='list-disc list-inside'>
      <p className='pb-1 pt-3 font-bold'>Service Areas:</p>
          <div className="grid grid-col-1 md:grid-cols-2">
          <li>Downtown Vancouver</li>
          <li>North Vancouver</li>
          <li>West Vancouver </li>
          <li>East Vancouver </li>
          <li>South Vancouver</li>
          <li>Richmond</li>
          <li>Langley</li>
          <li>Burnaby </li>
          <li>Surrey</li>
          <li>Delta</li>
          <li>Port Coquitlam </li>
          <li>Coquitlam</li>
          <li>New West</li>
          <li>Abbotsford</li>
          </div>
          <p className='mt-3 text-left'>and all surrounding areas.</p>
        </ul>
        </p>
           </div>
            <div className='w-full md:w-1/2 overflow-hidden'>
                <AreaSectionImage>
              <picture>
                <source type="image/webp" alt="mako-truck" srcset="assets/images/truck.webp" className="transform scale-90 md:scale-100 relative left-24 -translate-x-1/2 md:-left-24 sm:translate-x-0 sm:-top-5" />
                <img src="assets/images/truck.png" alt="mako-truck" className='transform scale-90 md:scale-100 relative left-24 -translate-x-1/2 md:-left-24 sm:translate-x-0 sm:-top-5' />
              </picture>
                </AreaSectionImage>
            </div>
        </div>
    </div>
  )
}
